//this is client given script, i keep all the comment line. 
// Sample dataset (descending coverage). Replace with your actual top 500 devices.
let deviceCoverageData = [
  { device: 'iPhone 11', coverage: 4.47 },
  { device: 'iPhone 12', coverage: 2.22 },
  { device: 'iPhone 12 Pro', coverage: 1.15 },
  { device: 'iPhone 12 Pro Max', coverage: 1.46 },
  { device: 'iPhone 13', coverage: 3.25 },
  { device: 'iPhone 13 Pro', coverage: 1.62 },
  { device: 'iPhone 13 Pro Max', coverage: 2.02 },
  { device: 'iPhone 14', coverage: 1.64 },
  { device: 'iPhone 14 Pro', coverage: 1.89 },
  { device: 'iPhone 14 Pro Max', coverage: 2.23 },
  { device: 'iPhone 15 Pro Max', coverage: 1.15 },
  { device: 'iPhone XR', coverage: 1.37 },
  { device: 'Smart 5', coverage: 0.06 },
  { device: 'Smart 7', coverage: 0.09 },
  { device: 'Galaxy A03 Core', coverage: 0.3 },
  { device: 'Galaxy A04e', coverage: 0.23 },
  { device: 'Galaxy A12 Nacho', coverage: 0.4 },
  { device: 'Galaxy A13', coverage: 0.69 },
  { device: 'Galaxy A14', coverage: 0.58 },
  { device: 'Galaxy A21s', coverage: 0.44 },
  { device: 'Galaxy A32', coverage: 0.51 },
  { device: 'Galaxy A54', coverage: 0.89 },
  { device: 'Galaxy S20 FE', coverage: 0.46 },
  { device: 'Galaxy S22', coverage: 0.95 },
  { device: 'Galaxy S23 Ultra', coverage: 1.07 },
  { device: 'Redmi Note 8', coverage: 0.46 },
].sort( ( a, b ) => b.coverage - a.coverage );

let currentSelectedDevices = [];

// Calculate ROI
function calculateROI() {
  // 1) Retrieve inputs
  const annualRevenue = parseFloat( document.getElementById( 'annualRevenue' ).value ) || 0;
  const costPerDevice = parseFloat( document.getElementById( 'costPerDevice' ).value ) || 0;
  const indirectDefectCost = parseFloat( document.getElementById( 'indirectDefectCost' ).value ) || 0;
  const otherTestingCosts = parseFloat( document.getElementById( 'otherTestingCosts' ).value ) || 0;
  const escapedDefectLikelihood = parseFloat( document.getElementById( 'escapedDefectLikelihood' ).value ) || 0;
  const defectDuration = parseFloat( document.getElementById( 'defectDuration' ).value ) || 1;
  const numberOfDevices = parseInt( document.getElementById( 'deviceSlider' ).value );

  // 2) Calculate total device cost
  const totalDeviceCost = costPerDevice * numberOfDevices;

  // 3) Sum coverage for top N devices to find coverageFraction
  let coverageSum = 0;
  const selectedDevices = [];
  for ( let i = 0; i < numberOfDevices && i < deviceCoverageData.length; i++ ) {
    coverageSum += deviceCoverageData[i].coverage;
    selectedDevices.push( deviceCoverageData[i] );
  }
  const coverageFraction = coverageSum / 100;
  document.getElementById( 'globalCoverage' ).textContent = coverageSum.toFixed( 2 ) + '%';

  // 4) Net Return Calculation
  const netReturn = 
    (
      annualRevenue *
      coverageFraction *
      ( escapedDefectLikelihood / 100 ) *
      ( defectDuration / 365 )
    )
    + indirectDefectCost
    - ( totalDeviceCost + otherTestingCosts );

  // 5) Display Net Return
  const netReturnRounded = Math.round( netReturn );
  document.getElementById( 'netReturn' ).textContent = formatDollar( netReturnRounded );

  // 6) Store selected devices for the modal
  currentSelectedDevices = selectedDevices;
}

// Format integer dollars (no decimals)
function formatDollar( num ) {
  return '$' + num.toLocaleString( undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  } );
}

// Update "Devices Selected" label
function updateDeviceCount() {
  const sliderValue = document.getElementById( 'deviceSlider' ).value;
  document.getElementById( 'selectedDevices' ).textContent = sliderValue;
}

// Modal functions
function updateCoverageTable( devices ) {
  const tableBody = document.getElementById( 'coverageTableBody' );
  tableBody.innerHTML = '';
  devices.forEach( device => {
    const row = document.createElement( 'tr' );
    const deviceCell = document.createElement( 'td' );
    const coverageCell = document.createElement( 'td' );
    deviceCell.textContent = device.device;
    coverageCell.textContent = device.coverage.toFixed( 2 ) + '%';
    row.appendChild( deviceCell );
    row.appendChild( coverageCell );
    tableBody.appendChild( row );
  } );
}

function openModal() {
  if ( currentSelectedDevices.length === 0 ) {
    alert( 'No devices selected to display.' );
    return;
  }
  updateCoverageTable( currentSelectedDevices );
  document.getElementById( 'devicesModal' ).style.display = 'block';
}

function closeModal() {
  document.getElementById( 'devicesModal' ).style.display = 'none';
}

// Attach event listeners after DOM loads
document.addEventListener( 'DOMContentLoaded', function() {
  const checkCalc = document.querySelector( '.cross-device-roi-calculator' );
  if ( !checkCalc ) return;
  // Range slider & input fields
  document.getElementById( 'costPerDevice' ).addEventListener( 'input', calculateROI );
  document.getElementById( 'annualRevenue' ).addEventListener( 'input', calculateROI );
  document.getElementById( 'indirectDefectCost' ).addEventListener( 'input', calculateROI );
  document.getElementById( 'otherTestingCosts' ).addEventListener( 'input', calculateROI );
  document.getElementById( 'escapedDefectLikelihood' ).addEventListener( 'input', calculateROI );
  document.getElementById( 'defectDuration' ).addEventListener( 'input', calculateROI );

  // Slider
  const deviceSlider = document.getElementById( 'deviceSlider' );
  deviceSlider.addEventListener( 'input', function() {
    updateDeviceCount();
    calculateROI();
  } );

  // Modal open/close
  document.getElementById( 'showDevicesBtn' ).addEventListener( 'click', openModal );
  document.getElementById( 'closeModalBtn' ).addEventListener( 'click', closeModal );

  // Close modal if user clicks outside of it
  window.addEventListener( 'click', function( event ) {
    const modal = document.getElementById( 'devicesModal' );
    if ( event.target === modal ) {
      closeModal();
    }
  } );

  // On initial load
  updateDeviceCount();
  calculateROI();
} ); 
